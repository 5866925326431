<template>
  <b-card>
    <form @submit.prevent="validateBeforeSubmit">
            <h1>Edit Profile</h1>
            <b-row>
              <b-col md="6">
                <label class="">First Name</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-validate="'required'" name="fname" v-model="fname" class="form-control" placeholder="First Name" />
                </b-input-group>
                <i v-show="errors.has('fname')" class="fa fa-exclamation-triangle mb-2"></i><span v-show="errors.has('fname')" class="help is-danger">The first name is required.</span>
              </b-col>
              <b-col md="6">
                <label class="">Last Name</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-user"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-validate="'required'" name="lname" v-model="lname" class="form-control" placeholder="Last Name" />
                </b-input-group>
                <i v-show="errors.has('lname')" class="fa fa-exclamation-triangle mb-2"></i><span v-show="errors.has('lname')" class="help is-danger">The last name is required.</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <label class="">Company Name</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="company_name" v-validate="'required'" name="company_name" class="form-control" placeholder="Company Name" />
                </b-input-group>
                <i v-show="errors.has('company_name')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('company_name')" class="help is-danger">The company name is required.</span>
              </b-col>
              <b-col md="6">
                <label class="">Address</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="addressline1" v-validate="'required'" name="addressline1" class="form-control" placeholder="Address Line 1" />
                </b-input-group>
                <i v-show="errors.has('addressline1')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('addressline1')" class="help is-danger">The address is required.</span>
              </b-col>
              <b-col md="6">
                <label class="">Address 2</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="addressline2" name="addressline2" class="form-control" placeholder="Address Line 2" />
                </b-input-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <label class="">City</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="city" v-validate="'required'" name="city" class="form-control" placeholder="City" />
                </b-input-group>
                <i v-show="errors.has('city')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('city')" class="help is-danger">The city is required.</span>
              </b-col>
              <b-col md="6">
                <label class="">Province</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-select type="select" :options="provinceOptions" v-model="province" v-validate="'required'" name="province" class="form-control" placeholder="Province"/>
                </b-input-group>
                <i v-show="errors.has('province')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('province')" class="help is-danger">The province is required. Max 6 characters.</span>
              </b-col>
              <b-col md="6">
                <label class="">Postal Code</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="postalcode" v-validate="'required|max:6'" name="postalcode" class="form-control" placeholder="Postal Code" />
                </b-input-group>
                <i v-show="errors.has('postalcode')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('postalcode')" class="help is-danger">The postal code is required.</span>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <label class="">Registration Company Number</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="rcn" v-validate="'required'" name="rcn" class="form-control" placeholder="Registration Company Number" />
                </b-input-group>
                <i v-show="errors.has('rcn')" class="fa fa-exclamation-triangle mb-2"></i><span v-show="errors.has('rcn')" class="help is-danger">The company number is required.</span>
              </b-col>
              <b-col md="6">
                <label class="">GST Number</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-building"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text" v-model="gst" v-validate="'required'" name="gst" class="form-control" placeholder="Vendor GST Number" />
                </b-input-group>
                <i v-show="errors.has('gst')" class="fa fa-exclamation-triangle"></i><span v-show="errors.has('gst')" class="help is-danger">The vendor GST number is required.</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <label class="">Phone Number</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-phone fa-flip-horizontal"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="number" v-model="phone_num" v-validate="'required|digits:10'" name="All" class="form-control" placeholder="Phone Number" />
                </b-input-group>
                <i v-show="errors.has('phone_num')" class="fa fa-exclamation-triangle mb-2"></i><span v-show="errors.has('phone_num')" class="help is-danger">{{ errors.first('phone_num') }}</span>
              </b-col>
              <b-col md="6">
                <label class="">Email Address</label>
                <b-input-group class="mb-2">
                  <b-input-group-prepend>
                    <b-input-group-text><i class="fa fa-envelope"></i></b-input-group-text>
                  </b-input-group-prepend>
                  <b-form-input type="text"  v-model="email" v-validate="'required|email'" name="email" class="form-control" placeholder="Email Address" />
                </b-input-group>
                <i v-show="errors.has('email')" class="fa fa-exclamation-triangle mb-2"></i><span v-show="errors.has('email')" class="help is-danger">The email is required.</span>
              </b-col>
            </b-row>
            <hr>
            <b-button variant="success" class="mt-4" type="submit" block>Edit Profile</b-button>
          </form>
  </b-card>
</template>

<script>
export default {
  name: 'WSSEditProfile',
  data: function () {
    return {
      email: '',
      oldemail: '',
      fname: '',
      lname: '',
      company_name: '',
      fax_num: '',
      phone_num: '',
      addressline1: '',
      addressline2: '',
      city: '',
      postalcode: '',
      province: null,
      premium_stat: null,
      gst: '',
      rcn: '',
      error: false,
      provinceOptions: [
        { value: null, text: 'Please select a province' },
        { value: "Alberta", text: 'Alberta'},
        { value: "British Columbia", text: 'British Columbia'},
        { value: "Manitoba", text: 'Manitoba'},
        { value: "New Brunswick", text: 'New Brunswick'},
        { value: "Newfoundland and Labrador", text: 'Newfoundland and Labrador'},
        { value: "Nova Scotia", text: 'Nova Scotia'},
        { value: "Ontario", text: 'Ontario'},
        { value: "Prince Edward Island", text: 'Prince Edward Island'},
        { value: "Quebec", text: 'Quebec'},
        { value: "Saskatchewan", text: 'Saskatchewan'},
        { value: "Northwest Territories", text: 'Northwest Territories'},
        { value: "Nunavut", text: 'Nunavut'},
        { value: "Yukon", text: 'Yukon'}
      ]
    }
  },
  mounted: function () {
    this.loadData();
  },
  methods: {
    validateBeforeSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = JSON.stringify({
            fname: this.fname,
            lname: this.lname,
            company_name: this.company_name,
            fax_num: this.fax_num,
            phone_num: this.phone_num,
            addressline1: this.addressline1,
            addressline2: this.addressline2,
            address: this.addressline1,
            city: this.city,
            postalcode: this.postalcode,
            province: this.province,
            gst: this.gst,
            oldemail: this.oldemail,
            newemail: this.email,
          })
          console.log(data)
          this.$http.post('/wss/edit/profile', data)
          .then(response => {
            if (response.status === 200) {
              this.$toasted.show('Successfully Updated', {icon: 'fa-check', type: 'success', duration: '3000'})
              this.loadData();
            }
          })
          .catch(error => {
            this.$toasted.show('Error. Please Try Again.', {type: 'error', duration: '3000'})
            this.error = true;
          })
          return;
        }
      });
    },
    loadData() {
      this.$http.get('/wss/get/profile')
      .then(response => {
        if (response.status === 200) {
          this.oldemail = response.data.email;
          this.email = response.data.email;
          this.fname = response.data.fname;
          this.lname = response.data.lname;
          this.company_name = response.data.company_name;
          this.fax_num = response.data.fax_num;
          this.phone_num = response.data.phone_num;
          this.addressline1 = response.data.addressline1;
          this.addressline2 = response.data.addressline2;
          this.city = response.data.city;
          this.postalcode = response.data.postalcode;
          this.province = response.data.province;
          this.gst = response.data.gst;
          this.rcn = response.data.rcn;
          this.premium_stat = response.data.premium_stat;
        }
      })
      .catch(error => {
        this.$toasted.show('Error.', {type: 'error', duration: '3000'})
        this.error = true;
      })
    }
  }
}
</script>
